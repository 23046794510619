<template>
  <section id="new-glodal-variable">
    <div class="page-header">
      <el-button
        size="mini"
        icon="el-icon-back"
        class="default-button btn button-left mb-2 mr-2"
        @click="goBack()"
        >Back
      </el-button>
      <el-breadcrumb class="mb-05" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">Dashboard</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/global-variables' }"
          >Global Variables</el-breadcrumb-item
        >
        <el-breadcrumb-item>New Variable</el-breadcrumb-item>
      </el-breadcrumb>
      <el-row align="middle" type="flex">
        <el-form
          v-model="globalVariable"
          :inline="true"
          class="add-user-box w-100"
        >
          <div class="float-right">
            <el-form-item class="select-type mb-0">
              Type
              <el-select
                v-model="globalVariable.input_type"
                placeholder="Select Data Type"
                @change="updateFieldData"
              >
                <el-option
                  v-for="(inputData, index) of uniqueInputTypes"
                  :key="index"
                  :label="inputData.name"
                  :value="inputData.inputType"
                ></el-option>
              </el-select>
              <p
                v-if="
                  getGlobalVariableErrors && getGlobalVariableErrors.input_type
                "
                class="error"
              >
                {{ getGlobalVariableErrors.input_type }}
              </p>
            </el-form-item>
          </div>
          <div class="add-var d-flex align-center mt-1">
            <div class="icon">
              <img
                alt="icon"
                src="@/assets/img/icons/menu/data-variable.svg"
                width="25"
              />
            </div>
            <div class="icon-text pl-1">
              <h4 class="f-weight-500 h4">{{ action }} Variable</h4>
            </div>
          </div>
        </el-form>
      </el-row>
    </div>
    <div class="card">
      <CriticalErrorMessage class="text-right" />
      <div class="inner-navbar-content">
        <div class="empty-message">
          <div
            v-if="!globalVariable.input_type"
            class="text-center grey-light-1 mt-2 mb-2"
          >
            Please select
            <code class="f-weight-500 h6">"Variable Type"</code> to enter data
          </div>
        </div>
        <SingleLineText
          v-if="
            globalVariable.input_type === 'SINGLE_LINE_TEXT' ||
            globalVariable.input_type === 'EMAIL' ||
            globalVariable.input_type === 'PASSWORD' ||
            globalVariable.input_type === 'MASKED'
          "
          :field="globalVariable"
        />
        <MultiLineText
          v-else-if="
            globalVariable.input_type === 'MULTI_LINE_TEXT' ||
            globalVariable.input_type === 'RICH_TEXT'
          "
          :field="globalVariable"
        />
        <Number
          v-else-if="globalVariable.input_type === 'NUMBER'"
          :field="globalVariable"
        />
        <Date
          v-else-if="globalVariable.input_type === 'DATE'"
          :field="globalVariable"
        />
        <Time
          v-else-if="
            globalVariable.input_type === 'TIME' ||
            globalVariable.input_type == 'FIXED_TIME'
          "
          :field="globalVariable"
        />
        <DateTime
          v-else-if="globalVariable.input_type === 'DATE_TIME'"
          :field="globalVariable"
        />
        <DateTimeRange
          v-else-if="globalVariable.input_type === 'DATE_TIME_RANGE'"
          :field="globalVariable"
        />
        <DateRange
          v-else-if="globalVariable.input_type === 'DATE_RANGE'"
          :field="globalVariable"
        />
        <TimeRange
          v-else-if="globalVariable.input_type === 'TIME_RANGE'"
          :field="globalVariable"
        />
        <Weekdays
          v-else-if="globalVariable.input_type === 'WEEKDAYS'"
          :field="globalVariable"
        />
        <File
          v-else-if="globalVariable.input_type === 'FILE'"
          :field="globalVariable"
        />
        <Select
          v-else-if="globalVariable.input_type === 'SELECT'"
          :field="globalVariable"
        />
        <YesOrNo
          v-else-if="globalVariable.input_type === 'YES_OR_NO'"
          :field="globalVariable"
        />
        <Heading
          v-else-if="globalVariable.input_type === 'HEADING'"
          :field="globalVariable"
        />
        <Checkbox
          v-else-if="globalVariable.input_type === 'CHECKBOX'"
          :field="globalVariable"
        />
        <Radio
          v-else-if="globalVariable.input_type === 'RADIO'"
          :field="globalVariable"
        />
        <CheckBoxGroup
          v-else-if="globalVariable.input_type === 'CHECKBOX_GROUP'"
          :field="globalVariable"
        />
        <StarRating
          v-else-if="globalVariable.input_type === 'STAR_RATING'"
          :field="globalVariable"
        />
        <RadioGroup
          v-else-if="globalVariable.input_type === 'RADIO_BUTTON_GROUP'"
          :field="globalVariable"
        />

        <MultiSelect
          v-else-if="globalVariable.input_type === 'MULTI_SELECT'"
          :field="globalVariable"
        />
        <ESignature
          v-else-if="globalVariable.input_type === 'SIGNATURE'"
          :field="globalVariable"
        />
        <GlobalVariable
          v-else-if="globalVariable.input_type === 'GLOBAL_VARIABLE'"
          :field="globalVariable"
        />
        <Paragraph
          v-else-if="globalVariable.input_type === 'PARAGRAPH'"
          :field="globalVariable"
        />
        <SingleLineContent
          v-else-if="globalVariable.input_type === 'SINGLE_LINE_CONTENT'"
          :field="globalVariable"
        />
        <AuthorizedSignature
          v-else-if="globalVariable.input_type === 'AUTHORIZED_SIGNATURE'"
          :field="globalVariable"
        />
        <Html
          v-else-if="globalVariable.input_type === 'HTML_CONTENT'"
          :field="globalVariable"
        />
        <ImageComponent
          v-else-if="globalVariable.input_type === 'IMAGE'"
          :field="globalVariable"
        />
        <List
          v-else-if="globalVariable.input_type === 'LIST'"
          :field="globalVariable"
        />
        <Currency
          v-else-if="globalVariable.input_type === 'CURRENCY'"
          :field="globalVariable"
        />
      </div>

      <div class="mt-05 mb-05 text-right">
        <el-button
          v-if="globalVariable.input_type"
          class="f-weight-600"
          icon="el-icon-add"
          type="success"
          :disabled="disableFieldButton(globalVariable)"
          @click="handleButtonClick"
          >{{ action }} Variable
        </el-button>
      </div>
    </div>
  </section>
</template>

<script>
import AuthorizedSignature from "@/components/templates/formComponents/AuthorizedSignature";
import { Notification } from "element-ui";
import CriticalErrorMessage from "@/components/form/CriticalErrorMessage";
import ESignature from "@/components/signature/ESignature";
import Checkbox from "@/components/templates/formComponents/Checkbox";
import Radio from "@/components/templates/formComponents/Radio";
import File from "@/components/templates/formComponents/File";
import Heading from "@/components/templates/formComponents/Heading";
import Paragraph from "@/components/templates/formComponents/Paragraph";
import templateData from "@/constants/lg-en/templates";
import {
  errorNotification,
  successNotification,
} from "@/helpers/notifications";
import { mapGetters } from "vuex";
import Date from "./globalVariableFormFields/Date";
import Time from "./globalVariableFormFields/Time";
import DateTime from "./globalVariableFormFields/DateTime";
import DateTimeRange from "./globalVariableFormFields/DateTimeRange";
import DateRange from "./globalVariableFormFields/DateRange";
import TimeRange from "./globalVariableFormFields/TimeRange";
import Html from "./globalVariableFormFields/Html";
import ImageComponent from "./globalVariableFormFields/Image";
import List from "./globalVariableFormFields/List";
import MultiLineText from "./globalVariableFormFields/MultiLineText";
import MultiSelect from "./globalVariableFormFields/MultiSelect";
import Number from "./globalVariableFormFields/Number";
import Select from "./globalVariableFormFields/Select";
import SingleLineContent from "./globalVariableFormFields/SingleLineContent";
import SingleLineText from "./globalVariableFormFields/SingleLineText";
import YesOrNo from "./globalVariableFormFields/YesOrNo";
import Currency from "./globalVariableFormFields/Currency";
import PermissionsHelper from "@/mixins/permissionsHelper";
import Weekdays from "./globalVariableFormFields/Weekdays.vue";
import StarRating from "./globalVariableFormFields/StarRating.vue";
import RadioGroup from "./globalVariableFormFields/RadioGroup.vue";
import CheckBoxGroup from "./globalVariableFormFields/CheckBoxGroup.vue";

import moment from "moment";

import {
  updateGlobalVariableData,
  addSingleGlobalVariableToLocal,
} from "@/repo/globalVariables";
export default {
  name: "NewGlobalVariable",
  components: {
    File,
    Checkbox,
    Radio,
    Heading,
    ESignature,
    Paragraph,
    AuthorizedSignature,
    CriticalErrorMessage,
    SingleLineText,
    MultiLineText,
    MultiSelect,
    Select,
    Number,
    Date,
    Time,
    DateTime,
    DateTimeRange,
    YesOrNo,
    SingleLineContent,
    Html,
    List,
    ImageComponent,
    Currency,
    DateRange,
    TimeRange,
    Weekdays,
    CheckBoxGroup,
    StarRating,
    RadioGroup,
  },
  mixins: [PermissionsHelper],
  data() {
    return {
      action: "Add",
      globalVariable: {
        name: "",
        input_type: "",
        options: [],
        value: "",
        image_url: "",
        currency: "",
        rows: "",
        Groups: [],
        validations: {
          required: false,
        },
      },
      allInputTypes: templateData.globalVariables,
    };
  },
  computed: {
    logoUploadUrl() {
      return process.env.VUE_APP_S3_BUCKET_URL;
    },
    uniqueInputTypes() {
      const uniqueSet = new Set();
      return this.allInputTypes.filter((inputData) => {
        if (!uniqueSet.has(inputData.inputType)) {
          uniqueSet.add(inputData.inputType);
          return true;
        }
        return false;
      });
    },
    ...mapGetters("globalVariables", [
      "getGlobalVariableAddStatus",
      "getGlobalVariablesAddData",
      "getGlobalVariableUpdateStatus",
      "getGlobalVariablesUpdateData",
      "getGlobalVariableErrors",
      "getGlobalVariableById",
    ]),
  },
  mounted() {
    this.$store.commit("errors/reset");
    this.selectedGlobalVariableId = this.$route.params.global_variable_id;
    this.selectedGlobalVariableAction =
      this.$route.query.global_variable_action;
    // Mukesh Duplicate Global Variable Feature
    if (this.selectedGlobalVariableId && this.selectedGlobalVariableAction) {
      this.fetchGlobalVariableData();
      this.action = "Duplicate";
    }

    // Mukesh Duplicate Global Variable Feature
    if (this.selectedGlobalVariableId && !this.selectedGlobalVariableAction) {
      this.fetchGlobalVariableData();
      this.action = "Update";
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    disableFieldButton() {
      if (!this.globalVariable.label) {
        return true;
      } else {
        return false;
      }
    },
    goToDashBoard() {
      this.$router.push({
        path: "/",
      });
    },
    goToGlobalVariables() {
      return this.$router.push({
        path: "/global-variables",
        query: {
          type: this.globalVariable.input_type,
        },
      });
    },
    hasLoginForUserType() {
      return this.selectedUserType && this.selectedUserType.has_login;
    },
    async updateFieldData() {
      if (this.globalVariable.input_type == "FIXED_TIME") {
        this.globalVariable = {
          ...this.globalVariable,
          ...{
            is_fixed: true,
            properties: {
              time_range: [
                moment("2016-10-10 00:00:00"),
                moment("2016-10-10 23:59:59"),
              ],
              step_time: "30",
            },
          },
        };
      }
    },
    handleButtonClick() {
      if (!this.checkPermissionByPermissionName("addGlobalVariables")) {
        this.$notify.error({
          title: "Error",
          message: "Permission denied. Please contact Owner.",
        });
      } else if (
        this.globalVariable.input_type == "STAR_RATING" &&
        this.globalVariable.value == 0
      ) {
        Notification.error({
          title: "Error",
          message: "Please rate this field",
          duration: 5000,
        });
      } else {
        if (this.globalVariable && /^\s/.test(this.globalVariable.label)) {
          // this.$message.warning('Field titles consisting only of spaces should not be considered');
          this.$message.error(
            "Field titles consisting only of spaces can't be considered"
          );
          return;
        }
        this.addGlobalVariable();
      }
    },
    async addGlobalVariable() {
      let updatelistField = (this.globalVariable.list_data || []).filter(
        (e) => (e && e.name == "") || e.value == ""
      );
      if (this.globalVariable && !this.globalVariable.label) {
        this.$message.error("Please add field title.!");
      }
      if (
        this.globalVariable &&
        this.globalVariable.input_type === "CHECKBOX_GROUP" &&
        ((this.globalVariable.options &&
          this.globalVariable.options.length === 0) ||
          this.globalVariable.min_selection == undefined ||
          this.globalVariable.max_selection == undefined)
      ) {
        if (
          this.globalVariable.options &&
          this.globalVariable.options.length === 0
        ) {
          this.$message.error("Please Fill at least one option!");
        } else {
          this.$message.error("Please Fill min and max values!");
        }
      }

      //       if (this.globalVariable && this.globalVariable.label) {
      //   // Check if the label contains only spaces
      //   const labelWithoutSpaces = this.globalVariable.label.replace(/\s/g, '');

      //   if (labelWithoutSpaces === '') {
      //     this.$message.error("Field title cannot be only spaces.");
      //   }
      // } else {
      //   this.$message.error("Please add field title.");
      // }
      else if (
        this.globalVariable &&
        this.globalVariable.input_type == "RADIO_BUTTON_GROUP" &&
        this.globalVariable.options.length == "0"
      ) {
        this.$message.error("Please Select Field options!");
      } else if (
        this.globalVariable &&
        this.globalVariable.input_type == "TIME" &&
        this.globalVariable.value == "" &&
        !this.globalVariable.is_fixed == true
      ) {
        this.$message.error("Please Fill Value option!");
      } else if (
        this.globalVariable &&
        this.globalVariable.input_type == "SELECT" &&
        this.globalVariable.options.length == "0"
      ) {
        this.$message.error("Please Select Field options!");
      } else if (
        this.globalVariable &&
        this.globalVariable.input_type == "MULTI_SELECT" &&
        this.globalVariable.options.length == "0"
      ) {
        this.$message.error("Please Select Field options!");
      } else if (
        this.globalVariable &&
        !this.globalVariable.value &&
        this.globalVariable.input_type != "SELECT" &&
        this.globalVariable.input_type != "MULTI_SELECT" &&
        this.globalVariable.input_type != "LIST" &&
        this.globalVariable.input_type != "DATE" &&
        this.globalVariable.input_type != "IMAGE" &&
        this.globalVariable.input_type != "CHECKBOX" &&
        this.globalVariable.input_type != "CHECKBOX_GROUP" &&
        this.globalVariable.input_type != "STAR_RATING" &&
        this.globalVariable.input_type != "RADIO" &&
        this.globalVariable.input_type != "RADIO_BUTTON_GROUP" &&
        this.globalVariable.input_type != "TIME"
      ) {
        this.$message.error("Please Enter Value!");
      } else if (
        this.globalVariable &&
        this.globalVariable.input_type == "LIST" &&
        this.globalVariable.list_data &&
        this.globalVariable.list_data.length == 0
      ) {
        this.$message.error("Please add list items data!");
      } else if (
        this.globalVariable &&
        this.globalVariable.input_type === "DATE" &&
        !this.globalVariable.value &&
        (this.globalVariable.datestamp == false ||
          !this.globalVariable.datestamp)
      ) {
        this.$message.error("Please Add Date Value!");
      } else if (
        this.globalVariable &&
        (this.globalVariable.input_type === "DATE_TIME" ||
          this.globalVariable.input_type === "DATE_TIME_RANGE") &&
        !this.globalVariable.value
      ) {
        this.$message.error("Please Add Value!");
      } else if (
        this.globalVariable &&
        this.globalVariable.input_type === "MULTI_LINE_TEXT" &&
        this.globalVariable.rows < 3
      ) {
        this.$message.error("Please Add Number Rows!");
      } else if (
        this.globalVariable &&
        this.globalVariable.input_type === "IMAGE" &&
        this.globalVariable.image_url === ""
      ) {
        this.$message.error("Please Upload the Image!");
      }
      //    else if( this.globalVariable && this.globalVariable.input_type==="LIST" && this.globalVariable.list_data.length === 0 && updatelistField &&
      //     updatelistField.length && updatelistField.length > 0){
      //   this.$message.error("Please add list item data!")

      //  }
      else if (
        updatelistField &&
        updatelistField.length &&
        updatelistField.length > 0
      ) {
        this.$message.error("Name and value cannot be empty!");
      } else if (
        this.globalVariable &&
        this.globalVariable.input_type == "CURRENCY" &&
        this.globalVariable.currency.length == "0"
      ) {
        this.$message.error("Please Select Currency Type!");
      } else if (
        this.globalVariable &&
        (this.globalVariable.input_type == "SINGLE_LINE_TEXT" ||
          this.globalVariable.input_type == "MULTI_LINE_TEXT") &&
        !this.globalVariable.value.trim()
      ) {
        this.$message.error("Value is required!");
      } else {
        let params = this.globalVariable;
        // if(this.globalVariable.input_type == 'FIXED_TIME') {
        //   this.globalVariable.input_type = "TIME"
        // }
        // Mukesh Duplicate Global Variable Feature
        if (this.selectedGlobalVariableAction === "duplicateGlobalVariable") {
          params.id = null;
          params._id = null;
          this.selectedGlobalVariableId = null;
          await this.$store.dispatch(
            "globalVariables/addGlobalVariable",
            params
          );
          if (this.getGlobalVariableAddStatus) {
            if (this.getGlobalVariablesAddData) {
              await addSingleGlobalVariableToLocal(
                this.getGlobalVariablesAddData
              );
            }
            successNotification("Global Variable added successfully");
            this.goToGlobalVariables();
          } else {
            if (!this.getGlobalVariableErrors) {
              errorNotification("Error at creating global user");
            }
          }
          // this.addGlobalVariable();
        }

        // Mukesh Duplicate Global Variable Feature

        if (!this.selectedGlobalVariableId) {
          await this.$store.dispatch(
            "globalVariables/addGlobalVariable",
            params
          );
          if (this.getGlobalVariableAddStatus) {
            if (this.getGlobalVariablesAddData) {
              await addSingleGlobalVariableToLocal(
                this.getGlobalVariablesAddData
              );
            }
            successNotification("Global Variable added successfully");
            this.goToGlobalVariables();
          } else {
            if (!this.getGlobalVariableErrors) {
              errorNotification("Error at creating global user");
            }
          }
        } else {
          params.id = this.selectedGlobalVariableId;
          await this.$store.dispatch(
            "globalVariables/updateGlobalVariable",
            params
          );

          if (this.getGlobalVariableUpdateStatus) {
            if (this.getGlobalVariablesUpdateData) {
              await updateGlobalVariableData(
                this.selectedGlobalVariableId,
                this.getGlobalVariablesUpdateData
              );
            }
            successNotification("Global Variable updated successfully");
            this.goToGlobalVariables();
          } else {
            this.$notify.error({
              title: "Error",
              message: "Name already existed.!",
            });
            if (!this.getGlobalVariableErrors) {
              errorNotification("Error at updating global user");
            }
          }
        }
      }
    },

    async fetchGlobalVariableData() {
      let params = {
        id: this.selectedGlobalVariableId,
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        this.globalVariable = { ...this.getGlobalVariableById };
        updateGlobalVariableData(
          this.selectedGlobalVariableId,
          this.globalVariable
        );
      }
    },
  },
};
</script>

<style lang="scss">
#new-glodal-variable {
  margin: 0 auto;
  max-width: 850px;

  .buttons > * {
    margin: 30px;
  }

  .card {
    background: #ffffff;
    border-radius: 6px;
    -webkit-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
    margin: 1.5em auto 0;
    padding: 1.25em 1.75em;
    width: auto;

    .error {
      color: rgb(202, 3, 3);
      font-size: 14px;
      padding-left: 5px;
    }
  }
  .add-user-box {
    position: relative;
  }
}
</style>
<style scoped>
@media (max-width:767px){
  .select-type{
    margin-top:5px;
  }
  .add-var{
    width:150px;
  }
}
</style>
